<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- game -->
      <template #cell(game)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              :src="require(`@/assets/images/icons/${mapTagToVariant(data.item.tag).svg}`)"
              alt="avatar img"
            /></b-avatar>
          <div>
            <b-link
              :to="{ name: 'content-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ strSubstring(data.item.name,20) }}
            </b-link>
            <div class="font-small-2 text-muted">
              <p v-html="strSubstring(data.item.description, 20)"></p>
            </div>
          </div>
        </div>
      </template>
      <!-- category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            class="mr-1"
            :variant="mapTagToVariant(data.item.tag).variant"
          >
            <feather-icon
              size="18"
              :icon="mapTagToVariant(data.item.tag).Icon"
            />
          </b-avatar>
          <span>{{ data.item.tag }}</span>
        </div>
      </template>
      <!-- start date -->
      <template #cell(Period)="data">
        <div class="d-flex flex-column">
          <span class="font-small-2 text-muted"> {{ formatDate(data.item.start) }} to  {{ formatDate(data.item.end) }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BAvatar, BImg, BLink,
} from 'bootstrap-vue'
import { mixinList } from '@/constants/mixinValidations'
import { mixinDate } from '@/constants/mixinDate'
import {
  mapTagToVariant,
} from '@/constants/utils'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BLink,
  },
  mixins: [mixinList, mixinDate],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'Game', label: 'GAME' },
        { key: 'Category', label: 'CATEGORY' },
        { key: 'Period', label: 'PERIOD' },
      ],
    }
  },
  setup() {
    return {
      mapTagToVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
