<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/meetup.jpeg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{ weekday }}
          </h6>
          <h3 class="mb-0">
            {{ today }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            <b-link
              :to="{ name: 'content-view', params: { id: data.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ strSubstring(data.name,15) }}
            </b-link>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="strSubstring(data.description,50)" />
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ formatDateTime(data.start) }}
          </h6>
        </b-media-body>

      </b-media>
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ formatDateTime(data.end) }}
          </h6>
        </b-media-body>

      </b-media>
      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="34px"
      >
        <b-avatar
          v-for="(user,index) in data.users.slice(0, 12)"
          :key="index"
          v-b-tooltip.hover.bottom="user.firstName + ' ' + user.lastName"
          :variant="changeVariant(index)"
          :text="avatarText(user.firstName.substring(0,1))"
          class="pull-up"
          :to="{ name: 'user-profile-id', params: { id: user.id } }"
        />
        <span
          v-if="data.users.length > 12"
          class="ml-1"
        > ...</span>
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          {{ data.usersCount }} {{ $t('PLAYERS') }} -  {{ data.groupCount }} {{ $t('TEAMS') }}
        </h6>
      </b-avatar-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BLink,
  BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinDate, mixinList],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      weekday: '',
      today: '',
    }
  },
  created() {
    this.getweekday()
    this.getToday()
  },
  methods: {
    getweekday() {
      this.weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][new Date().getDay()]
    },
    getToday() {
      this.today = String(new Date().getDate()).padStart(2, '0')
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
