<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        v-if="userData.userRoleId===userRole.ADMIN"
        xl="4"
        md="6"
      >
        <analytics-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <analytics-statistics :data="companyObjects" />
      </b-col>

      <b-col lg="4">
        <b-row class="match-height">
          <!-- Habit -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <analytics-habit
              :data="data.statisticsOrder"
              :habit-count="habitCount"
            />
          </b-col>
          <!--/ Behaviour -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <analytics-behaviour
              :data="data.statisticsProfit"
              :behaviour-count="behaviourCount"
            />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <analytics-app-usage
              v-if="userData.userRoleId===userRole.ADMIN"
              :data="data.earningsChart"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- Leader Board -->
      <b-col :lg="userData.userRoleId===userRole.ADMIN ? '8' : '12'">
        <analytics-leader-board :data="data.revenue" />
      </b-col>
      <!--/ Leader Board -->

      <!-- Current Games -->
      <b-col lg="8">
        <analytics-games-table :table-data="contents" />
      </b-col>
      <!--/ Current Games -->

      <!-- Today game -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-today-game :data="content" />
      </b-col>
    </b-row>
    <!--/ Today game -->
    <b-row
      v-if="userData.userRoleId===userRole.ADMIN"
      class="match-height"
    >
      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-browser-states />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Game Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-game-overview :data="gameOverview" />
      </b-col>
      <!--/ Game Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <analytics-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { countingObject } from '@/constants/utils'
import constants from '@/constants/static.json'
import AnalyticsMedal from './AnalyticsMedal.vue'
import AnalyticsStatistics from './AnalyticsStatistics.vue'
import AnalyticsLeaderBoard from './AnalyticsLeaderBoard.vue'
import AnalyticsHabit from './AnalyticsHabit.vue'
import AnalyticsBehaviour from './AnalyticsBehaviour.vue'
import AnalyticsAppUsage from './AnalyticsAppUsage.vue'
import AnalyticsGamesTable from './AnalyticsGamesTable.vue'
import AnalyticsTodayGame from './AnalyticsTodayGame.vue'
import AnalyticsBrowserStates from './AnalyticsBrowserStates.vue'
import AnalyticsGameOverview from './AnalyticsGameOverview.vue'
import AnalyticsTransactions from './AnalyticsTransactions.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsMedal,
    AnalyticsStatistics,
    AnalyticsLeaderBoard,
    AnalyticsHabit,
    AnalyticsBehaviour,
    AnalyticsAppUsage,
    AnalyticsGamesTable,
    AnalyticsTodayGame,
    AnalyticsBrowserStates,
    AnalyticsGameOverview,
    AnalyticsTransactions,
  },
  data() {
    return {
      data: {},
      contents: [],
      content: {
        id: 0,
        name: '',
        description: '',
        start: '',
        end: '',
        usersCount: 0,
        groupCount: 0,
        users: [],
      },
      gameOverview: {
        completed: 87,
        inprogress: 37,
      },
      userRole: constants.USER_ROLE,
      companyObjects: [
        {
          icon: 'AwardIcon',
          color: 'light-primary',
          title: '452',
          subtitle: 'Games',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '2598',
          subtitle: 'Players',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'LayoutIcon',
          color: 'light-danger',
          title: '172',
          subtitle: 'Companies',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '35',
          subtitle: 'Projects',
          customClass: '',
        },
      ],
      behaviourCount: '8846',
      habitCount: '2095',
      userData: useJwt.getUser(),
    }
  },
  computed: {
    ...mapState('team', ['allTeams']),
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        this.userData = useJwt.getUser()
        this.data.congratulations.name = this.userData.fullName.split(' ')[0] || this.userData.username
      })
    this.fetchGames()
  },
  methods: {
    ...mapActions('content', ['fetchAllContents']),
    ...mapActions('team', ['fetchAllTeams']),
    async fetchGames() {
      const response = await this.fetchAllContents()
      this.contents = response.data.slice(0, 5)
      if (this.contents.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        Object.assign(this.content, {
          ...this.contents[0],
          usersCount: 0,
          groupCount: 0,
          users: [],
        })
        await this.fetchAllTeams(this.content.id)
        const results = countingObject(this.allTeams)
        this.content.usersCount = results.usersCount
        this.content.groupCount = results.groupCount
        this.content.users = results.users
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
